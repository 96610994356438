@import '../../styles/variables';

$eggshell: #f3f1e7; // Define the eggshell color

.ArtisticProjectsPage {
  background-color: $eggshell; // Set the background color for the entire page

  .header {
    text-align: center;
    margin-bottom: 2rem;
    padding-top: 8rem;
    color: #6f148d;
    font-size: 2.5rem;
    font-weight: 700;
  }

  .carouselSection {
    margin-bottom: 3rem;
  }

  .carouselItem {
    text-align: center;
    padding: 1.5rem;
    background-color: $eggshell; // Set the background color for the cards
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
    }
  }

  .carouselImage {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    border-radius: 10px;
  }

  .sectionTitle {
    text-align: center;
    margin: 3rem 0 1.5rem;
    color: #6f148d;
    font-size: 2rem;
    font-weight: 700;
  }

  .projectDescription {
    text-align: left;
    margin-bottom: 2rem;
    color: #6f148d;
    padding: 0 2rem;
  }
}
