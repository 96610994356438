@import '../../styles/variables';

.ServicesPage {
  background-color: #f3f1e7; // Eggshell color
  color: #6f148d;
  min-height: 100vh;
  padding-bottom: 64px;

  .hero {
    background-color: #6f148d;
    color: #f3f1e7;
    padding: 96px 0;
    text-align: center;

    &Content {
      max-width: 800px;
      margin: auto;
      padding: 32px;
    }

    h2, h5 {
      font-family: $font-big;
    }
  }

  .introduction {
    padding: 64px 0;
    text-align: center;

    h4, p {
      font-family: $font-medium;
    }
  }

  .valuesSection {
    padding: 64px 0;
    background-color: #6f148d;
    color: #f3f1e7;

    .valuesCard {
      padding: 32px;
      background-color: #f3f1e7;
      color: #6f148d;
      text-align: center;
      transition: transform 0.3s, box-shadow 0.3s;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 20px #6f148d;
      }

      .serviceIcon {
        font-size: 50px;
        margin-bottom: 16px;
      }

      .cardTitle {
        font-family: $font-medium;
        font-size: 1.5rem;
        font-weight: bold;
      }

      .cardContent {
        font-family: $font-small;
        padding-bottom: 16px;
      }
    }
  }

  .serviceSection {
    padding: 64px 0;

    .serviceCard {
      padding: 32px;
      background-color: #f3f1e7;
      color: #6f148d;
      text-align: center;
      transition: transform 0.3s, box-shadow 0.3s;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 20px #6f148d;
      }

      .serviceIcon {
        font-size: 50px;
        margin-bottom: 16px;
      }

      .cardTitle {
        font-family: $font-medium;
        font-size: 1.5rem;
        font-weight: bold;
      }

      .cardContent {
        font-family: $font-small;
        padding-bottom: 16px;
      }

      .cardActions {
        justify-content: center;
      }
    }
  }
}
