@import '../../styles/variables';

.ContactPage {
  background-color: #f3f1e7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1 0 auto;
    padding-bottom: 8rem;
    padding-top: 12rem; // Increased padding above Contact Us
  }

  .contactDetails {
    padding: 2rem 0; // Increase padding to lower the section
    margin-top: 4rem;
  }

  .icon {
    font-size: 40px;
    color: #6f148d; // Purple color
    margin-bottom: 0.5rem;
    padding-top: 4rem;
  }

  .contactItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }

    .MuiTypography-root {
      color: #6f148d; // Set text color to purple
    }
  }
}
