@import '../../styles/variables';

.AboutPage {
  .hero {
    background-color: #6f148d;
    color: #f3f1e7;
    padding: 9rem 0;
    text-align: center;

    .fullWidthVideo {
      margin-top: 2rem;
    }
  }

  .heroContent {
    max-width: 1200px; // Widen the hero content container
    margin: auto;

    h2, h5 {
      font-family: $font-big;
    }

    h4 {
      padding-top: 4rem;
    }
  }

  .root {
    background-color: #f3f1e7;
    color: #6f148d;
    padding: 1rem 0;
  }

  .teamSection {
    padding: 2rem 0;

    .MuiGrid-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .teamCard {
    padding: 4rem;
    text-align: center;
    background-color: #f3f1e7;
    color: #6f148d;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    box-sizing: border-box; // Include padding and border in the element's total width and height

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 20px #6f148d;
    }

    .teamAvatar {
      width: 8rem;
      height: 8rem;
      margin: auto;
      margin-bottom: 2rem;
    }

    .teamName {
      font-family: $font-medium;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .teamRole {
      font-family: $font-small;
      font-size: 1.2rem;
      color: #6f148d;
      margin-bottom: 2rem;
    }

    .teamDescription {
      font-family: $font-small;
      font-size: 1rem;
      color: #6f148d;
    }
  }

  .videoContainer {
    width: 100%;
    max-width: 400px;
    margin-bottom: 2rem;

    video {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }

  .MuiGrid-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .teamCard,
    .videoContainer {
      height: 100%;
    }
  }
}
