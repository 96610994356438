@import '../../styles/variables';

.Footer-root {
  background: linear-gradient(135deg, #3d1059, #8e24aa); // Gradient purple background
  color: #f3f1e7; // Eggshell color
  padding: 32px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.Footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Footer-link {
  color: #f3f1e7; // Eggshell color for the text
  text-decoration: none;
  margin: 8px;
  &:hover {
    text-decoration: underline;
  }
}

.Footer-icon {
  color: #f3f1e7;
  margin: 8px;
  &:hover {
    color: #d1c4e9; // Lighten on hover
  }
}

.Footer-footerText {
  margin-top: 16px;
  color: #f3f1e7; // Ensure the footer text is eggshell color
}

.Footer-socialMedia {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
