@import '../../styles/variables';

.Navbar-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #f3f1e7;
}

.Navbar-navLinks {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 32px;
  flex-grow: 1;
}

.Navbar-link {
  text-decoration: none;
  color: inherit;
  font-size: 1.1rem;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #d1c4e9;
  }
}

.Navbar-toolbar {
  min-height: 70px;
  display: flex;
  justify-content: space-between;
}

.Navbar-menuButton {
  margin-right: 16px;
}

.Navbar-logo {
  height: 60px;
  margin-right: 16px;
}

.Navbar-menu {
  background-color: #6f148d;
  color: #f3f1e7;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Navbar-menuItem {
  padding: 12px 24px;
  color: #f3f1e7;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  font-size: 1rem;

  &:hover {
    background-color: #d1c4e9;
    color: #6f148d;
  }
}

.Navbar-appBar {
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.Navbar-emailIcon {
  color: #f3f1e7;

  &:hover {
    color: #d1c4e9;
  }
}

.button {
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 8px 16px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    color: #d1c4e9;
  }
}
