@import '../../styles/variables';

.HomePage {
  .hero {
    // Removed background image properties since we are using a video
    // background-image: url('../../assets/images/Hero.jpg');
    // background-size: cover;
    // background-position: center;

    color: #f3f1e7;
    padding: 12rem 0;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55vh;

    // Ensure the video covers the full hero area and stays behind the content
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 90vw;
      height: 70wh;
      object-fit: cover; // This ensures the video behaves like a background image
      z-index: -1; // Ensures the video is behind the hero content
    }
  }

  .heroContent {
    max-width: 800px;
    margin: auto;
    background-color: #6f148d;
    padding: 4rem;
    border-radius: 8px;
    position: relative;
    z-index: 2; // Keeps content above the video
  }

  .callToAction {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -50px; // Adjust this value to move the button lower
    left: 50%;
    transform: translateX(-50%);
  }

  .servicesSection {
    background-color: #f3f1e7;
    color: #6f148d;
    padding: 8rem 0;
  }

  .serviceCard {
    padding: 4rem;
    text-align: center;
    background-color: #f3f1e7;
    color: #6f148d;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    }
  }

  .serviceIcon {
    font-size: 50px;
    margin-bottom: 2rem;
  }

  .carouselSection {
    background-color: #6f148d;
    color: #f3f1e7;
    padding: 8rem 0;
    text-align: center;
  }

  .carouselItem {
    padding: 2rem;
  }

  .carouselImage {
    max-width: 100%;
    border-radius: 8px;
  }

  .carouselDescription {
    margin-top: 1rem;
  }

  .videoSection {
    background-color: #f3f1e7;
    color: #6f148d;
    padding: 8rem 0;
    text-align: center;
  }

  .videoContent {
    max-width: 80%;
    margin: auto;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .statsSection {
    background-color: #f3f1e7;
    color: #6f148d;
    padding: 5rem 0;
    text-align: center;
  }

  .statCard {
    padding: 2rem;
    text-align: center;
    background-color: #6f148d;
    color: #f3f1e7;
    border-radius: 8px;
    height: 50%;
  }

  .teamSection {
    background-color: #f3f1e7;
    color: #6f148d;
    padding: 5rem 0;
    text-align: center;
  }

  .teamCard {
    padding: 4rem;
    text-align: center;
    background-color: #f3f1e7;
    color:#6f148d;
    transition: transform 0.3s, box-shadow 0.3s;
    height: 60%;
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    }
  }

  .projectSection {
    background-color: #f3f1e7;
    color: #6f148d;
    padding: 5rem 0;
    text-align: center;
  }

  .projectCard {
    padding: 0;
    text-align: center;
    background-color: #f3f1e7;
    color: #6f148d;
    transition: transform 0.3s, box-shadow 0.3s;
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 20px #6f148d;
    }
  }

  .projectImage {
    max-width: 100%;
    border-radius: 8px;
  }

  .project-0 .projectImage {
    width: 100%;
    height: 100%;
  }

  .project-1 .projectImage {
    width: 150%;
    height: 150%;
  }

  .project-2 .projectImage {
    width: 100%;
    height: 100%;
  }

  .MuiSpeedDial-fab {
    background-color: #6f148d;
    &:hover {
      background-color: #5e126b;
    }
    width: 250px; // Make the button wider
    height: 56px; // Adjust height to make it ovalish
    border-radius: 28px; // Make it oval-shaped
    font-weight: bold; // Make text bold
  }

  .MuiSpeedDialAction-staticTooltipLabel {
    background-color: #f3f1e7;
    color: #6f148d;
  }

  .MuiSpeedDialAction-fab {
    background-color: #6f148d;
    color: #ffffff;
    display: flex;
    justify-content: center; // Center icon within button
    align-items: center; // Center icon vertically
  }

  .MuiFab-extended {
    width: 250px; // Ensure the button is wider
    height: 56px; // Adjust height to make it ovalish
    border-radius: 28px; // Make it oval-shaped
    font-weight: bold; // Make text bold
  }

  .MuiSpeedDialAction-root {
    display: flex;
    justify-content: center; // Center action button within its container
    width: 100%;
    cursor: pointer;
  }
}
